/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {SEO} from "smooth-doc/src/components/SEO";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    ol: "ol",
    li: "li",
    strong: "strong",
    pre: "pre",
    code: "code",
    blockquote: "blockquote"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "searching-in-a-vector",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#searching-in-a-vector",
    "aria-label": "searching in a vector permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Searching in a vector"), "\n", React.createElement(_components.p, null, "and program for linear and binary search in a vector Rust"), "\n", React.createElement(_components.h2, {
    id: "introduction",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#introduction",
    "aria-label": "introduction permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Introduction"), "\n", React.createElement(_components.p, null, "Searching is finding the index of an element in a collection of elements. Here, in this article, we will discuss how to search for a given element in a vector."), "\n", React.createElement(_components.p, null, "We can search for an element in a vector ar an array in 2 possible ways"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Linear Search :"), " In this, we traverse through whole vector till the element is found. Hence, it takes ", React.createElement(_components.strong, null, "O( n )"), " time complexity."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Binary Search :"), " In this, we only check mid-points instead of checking the whole vector. Hence, it takes only ", React.createElement(_components.strong, null, "O( log(n) )"), " time complexity. Vector must be sorted for Binary Search."), "\n"), "\n", React.createElement(_components.h2, {
    id: "linear-search",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#linear-search",
    "aria-label": "linear search permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Linear Search"), "\n", React.createElement(_components.p, null, "In the Linear Search, we traverse the whole vector, and check if the given element is equal to key. If the element is found, its index is returned."), "\n", React.createElement(_components.p, null, "As it traverses the whole array, it takes ", React.createElement(_components.strong, null, "O( n )"), " time complexity."), "\n", React.createElement(_components.p, null, "Also, ", React.createElement(_components.strong, null, "order of array does not matter"), " for linear search. It is very simple to implement. Here is a simple function demonstrating Linear Search"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn search(vecky:&Vec<usize>, key:usize) -> usize{\n    for i in 0..vecky.len() {\n        if vecky[i] == key {\n            return i;\n        }\n    }\n\n    // If element is not found\n    return vecky.len();\n}\n")), "\n", React.createElement(_components.p, null, "Program With driver code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn search(vecky:&Vec<usize>, key:usize) -> usize{\n    for i in 0..vecky.len() {\n        if vecky[i] == key {\n            return i;\n        }\n    }\n\n    // If element is not found\n    return vecky.len();\n}\n\n// Driver code\n\nfn main() {\n    let vecky = vec![0, 1, 2, 3, 4, 5];\n    println!(\"{}\", search(&vecky, 4) );\n    println!(\"{}\", search(&vecky, 100) );\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Output")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "4 ", React.createElement("br"), "\n6"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Time Complexity : O( n )"), " ", React.createElement("br"), "\n", React.createElement(_components.strong, null, "Space Complexity : O( 1 )")), "\n", React.createElement(_components.p, null, "The standard crate in Rust only has ", React.createElement(_components.code, null, "binary_search()"), " and ", React.createElement(_components.code, null, "contains()"), " function. So, you will have to make your own search function for Linear Search, as above"), "\n", React.createElement(_components.h2, {
    id: "binary-search",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#binary-search",
    "aria-label": "binary search permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Binary Search"), "\n", React.createElement(_components.p, null, "In the Binary Search, we only check the midpoints of a ", React.createElement(_components.strong, null, "sorted list"), ". Each time, we have to search only in half of the list. Hence, its complexity is ", React.createElement(_components.strong, null, "O( log(n) )"), "."), "\n", React.createElement(_components.p, null, "Also, the vector ", React.createElement(_components.strong, null, "must be sorted"), " for Binary search."), "\n", React.createElement(_components.p, null, "Here is the Algorithm"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Calculate the mid-point of the ", React.createElement(_components.strong, null, "sorted"), " vector."), "\n", React.createElement(_components.li, null, "If the element is equal to the mid-point, return the index of the mid-point."), "\n", React.createElement(_components.li, null, "If the Element is greater than the mid-point, search for the element in the slice containing the larger values."), "\n", React.createElement(_components.li, null, "If the element is less than the mid-point, search the elements in slice containing smaller values than the mid-point."), "\n", React.createElement(_components.li, null, "Go back to step 2, till we have no elements left in the slice."), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Note :"), " If we observe carefully, each time if the element is not found, we have to find for the element in only the half of the vector. So, it takes only ", React.createElement(_components.strong, null, "O ( log(n) )"), " for finding any element."), "\n", React.createElement(_components.p, null, "Here is the function for Binary Search in Rust"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn binary_search(vecky:&Vec<usize>, key:usize) -> usize{\n    let mut low = 0;\n    let mut high = vecky.len()-1;\n\n    while low <= high {\n        let mid = low + (high - low) / 2;\n\n        // If key is middle element, return it\n        if vecky[mid] == key {\n            return mid;\n        }\n\n        // If key is greater than middle element, we ignore left half\n        if vecky[mid] < key {\n            low = mid + 1;\n        }\n\n        // If key is less than middle element, we ignore right half\n        else{\n            high = mid - 1;\n        }\n    }\n    // If the element is not found\n    return vecky.len();\n}\n")), "\n", React.createElement(_components.p, null, "Use the same driver code."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Output")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "4 ", React.createElement("br"), "\n6"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Time Complexity : O( log(n) )"), " ", React.createElement("br"), "\n", React.createElement(_components.strong, null, "Space Complexity : O( 1 )")), "\n", React.createElement(_components.h2, {
    id: "binary_search-function",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#binary_search-function",
    "aria-label": "binary_search function permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "binary_search() function"), "\n", React.createElement(_components.p, null, "Rust already has ", React.createElement(_components.strong, null, React.createElement(_components.code, null, "binary_search()")), " function built into it. It is already there in ", React.createElement(_components.code, null, "std::vec::Vec"), " and hence, included in prelude. So, you don't even have to import it explicitly."), "\n", React.createElement(_components.p, null, "For example,"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn main() {\n    let vecky = vec![0, 1, 2, 3, 4, 5];\n    println!(\"{}\", vecky.binary_search(&4).expect(\"Not found\") );\n    println!(\"{}\", vecky.binary_search( &100).expect(\"Not Found\") );\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Output")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "4 ", React.createElement("br"), "\nthread 'main' panicked at 'Not Found: 6'"), "\n"), "\n", React.createElement(_components.p, null, "Hence, you can easily use ", React.createElement(_components.code, null, "binary_search()"), " function on any vector or  a slice of vector in Rust."), "\n", React.createElement(_components.h2, {
    id: "conclusion",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#conclusion",
    "aria-label": "conclusion permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Conclusion"), "\n", React.createElement(_components.p, null, "Searching is an important operation in a Vector. In this article, we discussed Linear Search as well as Binary Search and saw their programs and also how to use built-in function to perform Search in Rust."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Thank You")), "\n", "\n", React.createElement(SEO, {
    title: "Searching in a Vector - Rust Programming",
    description: "Searching is an important operation in a Vector. In this article, we will discuss Linear Search as well as Binary Search and see their programs and also how to use built-in function to perform Search in Rust"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
